<template>
  <div v-if="channel" :class="$style.tvChannelCard">
    <div :class="$style.tvChannelCardContainer">
      <app-link
        :to="link"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Main, 'tvChannelCard')"
        :class="$style.tvChannelCardLink"
      >
        <div :class="$style.topBlock">
          <slot name="background">
            <app-img v-if="imgUrl" :src="imgUrl" :width="$isMobile ? 320 : 640" />
          </slot>

          <div :class="$style.logoBlock">
            <tv-channel-logo v-if="channel.logoWhite" :src="channel.logoWhite" :class="$style.logoImage" />
            <app-img v-else :width="$isMobile ? 320 : 600" :src="channel.logo" :image-class="$style.logoImage" />
          </div>

          <span
            v-if="isStickerVisible"
            :class="{
              [$style.accessSticker]: true,
              [$style.accessStickerAccent]: isAccentSticker,
            }"
          >
            {{ tvStickerText }}
          </span>
          <div
            v-if="channel.currentProgram?.startTime && channel.nextProgram?.startTime"
            :class="$style.progressBar"
            :style="{
              width: `${getPlayProgressPercent(channel.currentProgram.startTime, channel.nextProgram.startTime)}%`,
            }"
          ></div>
        </div>

        <div :class="$style.bottomBlock">
          <time
            v-if="channel.currentProgram?.startTime"
            :datetime="channel.currentProgram.startTime"
            :class="$style.time"
          >
            {{ $formatDateWithCustomFormat(new Date(channel.currentProgram.startTime), 'HH:mm') }}
          </time>
          <div :class="$style.program">
            <p :class="$style.programTitle">{{ channel.currentProgram?.title }}</p>
            <p v-if="description" :class="$style.programDescription">{{ description }}</p>
            <p v-if="isGenreExist" :class="$style.programGenre">{{ programGenre }}</p>
          </div>
        </div>
      </app-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { Channel } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import getTvChannelStickerText from '../../code/tv/get-tv-channel-sticker-text';
import useLocale from '../../platform/localization/use-locale';
import { useSessionStore } from '../../stores/use-session-store';
import AppImg from '../ui/AppImg.vue';
import AppLink from '../ui/AppLink.vue';
import TvChannelLogo from './TvChannelLogo.vue';

const props = defineProps<{
  channel: Channel;
  link: RouteLocationRaw;
  imgUrl?: string;
}>();

const { isAuth } = storeToRefs(useSessionStore());
const { translate } = useLocale();

const tvStickerText = computed(() => getTvChannelStickerText(props.channel.accessKind));
const isStickerVisible = computed(() => !!props.channel?.accessKind && Boolean(tvStickerText.value));
const isAccentSticker = computed(() => !isAuth.value && props.channel?.accessKind === 'register');

const getPlayProgressPercent = (startTime: string, endTime: string) => {
  const now = Date.now();
  const start = Date.parse(startTime);
  const end = Date.parse(endTime);
  const duration = end - start;
  const progress = now - start;
  const percent = Math.round((progress * 100) / duration);
  return Math.round((percent * 100) / 100);
};

const createDescription = (seasonNumber?: number, episodeNumber?: number, episodeTitle?: string) => {
  if (!seasonNumber && !episodeNumber) {
    return episodeTitle;
  }

  if (seasonNumber && episodeNumber) {
    return translate('content.season.and.episode', { seasonNumber, episodeNumber });
  }

  return '';
};

const description = computed(() =>
  createDescription(
    props.channel.currentProgram?.seasonNumber,
    props.channel.currentProgram?.episodeNumber,
    props.channel.currentProgram?.episodeTitle,
  ),
);

const isGenreExist = computed(() => props.channel.currentProgram?.genre && props.channel.currentProgram?.genre.length);
const programGenre = ref('');

if (isGenreExist.value && props.channel.currentProgram?.genre) {
  programGenre.value =
    props.channel.currentProgram.genre[0].slice(0, 1).toUpperCase() + props.channel.currentProgram.genre[0].slice(1);
}
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.tvChannelCard {
  height: 100%;
  border-radius: 24px;
  background-color: var(--color-bg-tertiary);
  overflow: hidden;
}

.tvChannelCardContainer {
  width: 100%;
  height: 100%;
}

.tvChannelCardLink {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  max-width: 100%;
  height: 100%;

  &:hover {
    color: inherit;
  }

  &:hover .programTitle {
    color: var(--color-text-accent);
  }
}

.topBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 214px;
  overflow: hidden;
}

.logoBlock {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 92px;
  background: var(--gradient-to-bottom);
}

.logoImage {
  margin-bottom: 22px;
  padding-left: var(--g-spacing-16);
  max-height: 33px;
  //width: fit-content;
}

.accessSticker {
  @include fonts.WebLabel-3;
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 5px var(--g-spacing-16);
  border-radius: var(--g-border-round-8);
  background-color: var(--color-bg-primary);
}

.accessStickerAccent {
  background-color: var(--g-purple-color);
}

.progressBar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 6px;
  border-radius: 0 6px 6px 0;
  background-color: var(--color-bg-accent);
}

.bottomBlock {
  display: flex;
  padding: var(--g-spacing-16);
  height: auto;
  margin-bottom: auto;
}

.time {
  @include fonts.WebLabel-2;
  margin-right: var(--g-spacing-12);
  color: var(--color-text-secondary);
}

.program {
  max-height: 105px;
  overflow: hidden;
}

.programTitle {
  @include fonts.WebBody-1;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: color 0.3s ease-in-out;
}

.programDescription {
  @include fonts.WebBody-2;
  display: -webkit-box;
  overflow: hidden;
  color: var(--color-text-secondary);
  margin-top: var(--g-spacing-4);
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.programGenre {
  @include fonts.WebLabel-3;
  margin-top: var(--g-spacing-4);
  color: var(--color-text-secondary);
}
</style>
