import { MediaMapper } from '../content/media';
import { ContentMoment } from '../content/types/content-moment';
import { ApiContentMoment } from './moment-types/content-moment';

export class ContentMomentMapper {
  static mapMany(moments: ApiContentMoment[]): ContentMoment[] {
    return moments.map(ContentMomentMapper.map);
  }

  static map({
    start_offset,
    end_offset,
    default_moment_playlist_id,
    moment_playlist_ids,
    content_poster,
    season_number,
    episode_number,
    content_id,
    content_slug,
    content_type,
    content_year,
    content_title,
    in_user_collection,
    like_state,
    primary_content,
    ...rest
  }: ApiContentMoment): ContentMoment {
    return {
      ...rest,
      startOffset: start_offset,
      endOffset: end_offset,
      defaultMomentPlaylistId: default_moment_playlist_id,
      momentPlaylistIds: moment_playlist_ids || [],
      contentPoster: content_poster,
      seasonNumber: season_number,
      episodeNumber: episode_number,
      contentId: content_id,
      contentSlug: content_slug,
      contentType: content_type,
      contentTitle: content_title,
      contentYear: content_year,
      inUserCollection: in_user_collection,
      likeState: like_state,
      primaryContent: primary_content ? MediaMapper.map(primary_content) : undefined,
    };
  }
}
