import type { MainPage, Media } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';

import { CookieName } from '@/platform/cookies/cookies';
import { useLogger } from '@/platform/logger/use-logger';
import { useSessionStore } from '@/stores/use-session-store';

import useAppCookie from '../../platform/cookies/use-app-cookie';
import type { IMainPageFetchBlockOptions } from '../main-page/use-main-page-api';
import { useMainPageApi } from '../main-page/use-main-page-api';
import { useRecommendationsApi } from '../recommendations/use-recommendations-api';
import { useWatchingItemsApi } from '../watching-items/use-watching-items-api';

export function useMainPageContent() {
  const sessionStore = useSessionStore();
  const mainPageApi = useMainPageApi();
  const logger = useLogger();

  const { isAuth } = storeToRefs(sessionStore);

  const { fetchContinueWatchItems } = useWatchingItemsApi();
  const { fetchCold, fetchPersonal } = useRecommendationsApi();

  const fetchBlocks = (options?: IMainPageFetchBlockOptions): Promise<MainPage> => {
    return mainPageApi
      .fetchBlocks(options)
      .then((response) => {
        const responseCopy = [...response];

        return responseCopy.sort((item, nextItem) => item.position - nextItem.position);
      })
      .catch(() => []);
  };

  const fetchWatchingItems = (): Promise<Media[]> => {
    if (isAuth.value) {
      return fetchContinueWatchItems().catch(() => []);
    }

    return Promise.resolve([]);
  };

  const fetchRecommendations = async (): Promise<Media[]> => {
    if (isAuth.value) {
      return fetchPersonal().catch(() => [] as Media[]);
    }

    // Для авторизованных юзеров, в холодных рекомендациях может вернуться взрослый контент
    // Поскольку в профиле мы не разделяем детский/недетский профиль, то для авторизованных рекомендации не запрашиваем
    if (isAuth.value) {
      return Promise.resolve([]);
    }

    const visitorId = useAppCookie(CookieName.VisitorId);

    try {
      if (!visitorId.value) {
        return [];
      }

      const cold = await fetchCold(visitorId.value);
      return cold;
    } catch (e) {
      logger.error(e);

      return [];
    }
  };

  return {
    fetchBlocks,
    fetchRecommendations,
    fetchWatchingItems,
  };
}
